import { Box, Menu, MenuItem, Stack, styled, SxProps, Theme } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { useEffect, useState } from "react"
import { Checkbox } from "../../generics/Checkbox"
import { Icon } from "../../generics/Icons"
import { LoadingContainer } from "../../generics/LoadingContainer"
import { Button } from "../../generics/button"
import { Divider } from "../../generics/divider"
import { TextField } from "../../generics/textfield"
import { Typography } from "../../generics/typographie"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"
import { IconButton } from "./IconButton"

const CustomMenuEntry = styled(MenuItem)({
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "6px",
})

const highlightedIconStyle: SxProps<Theme> = (theme) => ({
    width: "111px",
    height: "32px",
    borderRadius: "3px",
    color: theme.colors?.light,
    backgroundColor: theme.colors?.highlight,
    "&:hover": {
        backgroundColor: theme.colors?.["highlight-hover"],
    },
})

const SINGLEWATCHLISTNAME = "Watchlist"

export function Watchlist() {
    const {
        isInWatchList,
        watchLists: initialWatchList,
        toggleWatchList,
        createWatchList,
        isToggling,
        isLoading,
        isCreating,
        createOrAddWatchlist,
        singleWatchlist,
    } = useDefaultArticleItemState((x) => x.watchList)
    const { translateText } = useLocalization()
    const [creationMode, setCreationMode] = useState(false)
    const [newListName, setNewListName] = useState("")
    const [watchLists, setWatchLists] = useState(initialWatchList ?? [])
    const articleListExpanded = useDefaultArticleItemState((x) => x.expanded)
    const { highlightWatchlistButton } = useDefaultArticleItemState((x) => x.options)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLLIElement>) => {
        if (singleWatchlist) {
            createOrAddWatchlist(SINGLEWATCHLISTNAME)
        } else {
            setAnchorEl(event.currentTarget)
        }
    }
    const handleClose = () => {
        setCreationMode(false)
        setAnchorEl(null)
    }

    const createWatchlist = () => {
        createWatchList(newListName)
        setNewListName("")
        setCreationMode(false)
    }

    useEffect(() => {
        // Only set the watchlist when we have the new watchlist available
        if (initialWatchList && initialWatchList?.length > 0) {
            setWatchLists(initialWatchList)
        }
    }, [initialWatchList])

    return (
        <>
            {articleListExpanded || highlightWatchlistButton ? (
                <IconButton onClick={handleClick} sx={highlightWatchlistButton ? highlightedIconStyle : {}}>
                    <Icon name={isInWatchList ? "filled-star" : "vote-star"} />
                </IconButton>
            ) : (
                <MenuItem onClick={handleClick}>
                    <Stack direction="row" gap={1}>
                        <Icon name={isInWatchList ? "filled-star" : "vote-star"} />
                        <Typography variant="body2">{translateText(1240)}</Typography>
                    </Stack>
                </MenuItem>
            )}
            <Menu
                sx={{ maxHeight: "600px" }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <LoadingContainer isLoading={isToggling || isLoading || isCreating}>
                    {watchLists.map((listEntry) => {
                        return (
                            <CustomMenuEntry key={listEntry.id} onClick={() => toggleWatchList(listEntry.id)}>
                                <Checkbox size="small" checked={listEntry.selected} onClick={() => toggleWatchList(listEntry.id)} />
                                {listEntry.name}
                            </CustomMenuEntry>
                        )
                    })}
                </LoadingContainer>
                <Divider />
                {creationMode ? (
                    <MenuItem disableRipple onKeyDown={(e) => e.stopPropagation()}>
                        <Stack direction="row" spacing="4px">
                            <TextField
                                disabled={isCreating}
                                sx={{ marginTop: 0 }}
                                onChange={(e) => setNewListName(e.currentTarget.value)}
                                value={newListName}
                            />
                            <Button disabled={isCreating} onClick={handleClose} startIcon={<Icon name="cancel-x" />} />
                            <Button disabled={isCreating} onClick={createWatchlist} startIcon={<Icon name="check" />} color="success" />
                        </Stack>
                    </MenuItem>
                ) : (
                    <MenuItem>
                        <Box onClick={() => setCreationMode(true)} display="flex">
                            <Icon name="plus" sx={{ marginRight: "4px" }} /> <Typography>{translateText(1242)}</Typography>
                        </Box>
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}
