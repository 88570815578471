import { Grid, styled } from "@mui/material"
import { StyledComponent } from "@mui/styles"
import { Button, ButtonProps } from "../../../../generics/button"

export const ExpandIconButton = styled(Button)`
    &&& {
        border: 1px solid #b4b4b4;
        height: auto;
        width: auto;
        border-radius: 3px;
        padding: 2px;

        svg {
            width: 16px;
            height: 16px;
            min-height: 16px;
            min-width: 16px;
        }
    }
` as StyledComponent<ButtonProps>

export const GridItem = styled(Grid)({
    position: "relative",
    paddingRight: "12px",
    alignSelf: "stretch",
    "::after": {
        content: '" "',
        position: "absolute",
        right: 0,
        top: 0,
        height: "100%",
        borderRight: "solid 1px #c8c6c6",
    },
}) as typeof Grid
