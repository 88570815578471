import { ArticleAction, ECounterType } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { styled } from "@mui/system"
import { IconButton as MuiIconButton } from "@mui/material"
import { Icon } from "../../../generics/Icons"
import { Tooltip } from "../../../generics/tooltip"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { ImsIcon } from "../ImsIcon"
import { sendTmaEvent } from "./ActionsTmaHelper"

type IconActionProps = {
    action: ArticleAction
}

const StyledIconButton = styled(MuiIconButton)({
    width: "20px",
    height: "20px",
    padding: "0",
    margin: "0",

    svg: {
        width: "100%",
        height: "100%",
    },
})

export function IconAction({ action: { tooltip, text, handler, icon, isHighlight, id } }: IconActionProps) {
    const imsIcon = useDefaultArticleItemState((x) => x.imsIcon)

    const handleClickEventWithTma = () => {
        handler()
        sendTmaEvent(id)
    }

    return (
        <Tooltip title={tooltip ?? text}>
            <StyledIconButton onClick={handleClickEventWithTma}>
                {icon === "trader-icon" && imsIcon ? <ImsIcon /> : <Icon name={icon} color={isHighlight ? "primary" : undefined} />}
            </StyledIconButton>
        </Tooltip>
    )
}
