import { ComponentType, memo, useMemo } from "react"
import { TmaHelper } from "@tm/utils"
import { Box, Stack, StackProps, styled } from "@mui/material"
import { AttributeItem } from "./AttributeItem"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { Typography } from "../../../generics/typographie"
import { Icon } from "../../../generics/Icons"

export const AttributesBlock = styled(Stack)(({ theme }) => ({
    backgroundColor: "#f0f0f0",
    borderLeft: `2px solid ${theme.palette.highlight.main}`,
    marginTop: 2,
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    padding: "8px",

    "&:first-of-type": {
        marginTop: 0,
    },
})) as ComponentType<StackProps>

export const Attributes = memo(() => {
    const attributes = useDefaultArticleItemState((x) => x.displayArticle.attributes)
    const handleClickAttribute = useDefaultArticleItemState((x) => x.handleClickAttribute)
    const sharedAttributes = useDefaultArticleItemState((x) => x.displayArticle.sharedAttributes)

    const viewedAttributes = useMemo(() => {
        if (attributes && attributes?.length > 1) {
            return attributes?.map((val) => {
                return {
                    topArticleAttributes: val.topArticleAttributes?.filter((topAttribute) => !topAttribute.isSharedAttribute),
                    additionalAttributes: val.additionalAttributes?.filter((topAttribute) => !topAttribute.isSharedAttribute),
                }
            })
        }
        return attributes
    }, [attributes])

    const handleClick = useMemo<typeof handleClickAttribute>(
        () =>
            handleClickAttribute
                ? (is, value) => {
                      TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
                      return handleClickAttribute(is, value)
                  }
                : undefined,
        [handleClickAttribute]
    )

    return (
        <Stack spacing="4px">
            {!!sharedAttributes?.length && (
                <AttributesBlock>
                    {sharedAttributes?.map((attr, attrIndex) => (
                        <AttributeItem
                            key={`${attr.id}:${attr.key}`}
                            attribute={attr}
                            onClickAttributeValue={handleClick}
                            hideDivider={attrIndex + 1 === sharedAttributes?.length}
                        />
                    ))}
                </AttributesBlock>
            )}

            {viewedAttributes?.map(({ topArticleAttributes, additionalAttributes }, blockIndex) => (
                <Stack direction="row" key={`vehicleAttribute_${blockIndex}`} alignItems="start">
                    {viewedAttributes?.length > 1 && (
                        <Box alignItems="center" mt="10px" mr={1} whiteSpace="nowrap">
                            <Typography variant="label">{blockIndex + 1}.</Typography>&nbsp;
                            <Icon name="car" sx={{ verticalAlign: "top" }} />
                        </Box>
                    )}
                    <AttributesBlock>
                        {topArticleAttributes?.map((attr, attrIndex) => (
                            <AttributeItem
                                key={`${attr.id}:${attr.key}`}
                                attribute={attr}
                                onClickAttributeValue={handleClick}
                                hideDivider={attrIndex + 1 === topArticleAttributes?.length && !additionalAttributes?.length}
                            />
                        ))}

                        {additionalAttributes?.map((attr, attrIndex) => (
                            <AttributeItem
                                key={`${attr.id}:${attr.key}`}
                                hideDivider={attrIndex + 1 === additionalAttributes?.length}
                                attribute={attr}
                                onClickAttributeValue={handleClick}
                            />
                        ))}
                    </AttributesBlock>
                </Stack>
            ))}
        </Stack>
    )
})
