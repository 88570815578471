import { VehicleType } from "./enumerations"

export type ActivateModuleProps = {
    routes?: Record<string, string> | undefined
    demoActivationInstanstly?: boolean
    workTaskId?: string
}

export type ActivateModuleRef = null | {
    handleActivateModule: (
        moduleGroupId: ModuleGroupId,
        module: ActivatableModule,
        moduleOption?: ModuleOption,
        modulePackage?: ModulePackage
    ) => void
}

export type GetActivatableModulesResponse = {
    moduleGroups: Array<ModuleGroup>
}

export type ModuleGroup = {
    id: ModuleGroupId
    modules: Array<ActivatableModule>
}

export type ModuleGroupId = "gpi" | "fastCalculator" | "technicalData" | "technicalDataTruck"
export type ModuleId = "dat" | "eurotax" | "tecrmi" | "autodata" | "haynespro" | "topmotive"
export type ModuleMode = "NONE" | "SD" | "TD"

export enum MaxDemoModulePackageId {
    tecRMICarPro = "3",
    haynesProUltimate = "5"
}

export type ActivatableModule = {
    id: ModuleId
    externalId: string
    name: string
    shortDescriptionUrl: string
    longDescriptionUrl: string
    vehicleType: VehicleType
    moduleOptions: Array<ModuleOption>
    modulePackages: Array<ModulePackage>
    hasServiceData?: boolean
    explanationVideoUrl?: string
}

export type ModuleOption = {
    type: ModuleOptionType
    modulePrices?: Array<ModuleOptionPrice>
    active?: boolean
    activatable?: boolean
    amountOfDays?: number
    from?: Date
    to?: Date
}

export type ModulePackage = {
    moduleInformation: ModuleInformation
    moduleAvailabilities: Array<ModuleOption>
    validation: Validation
}

export type ModuleInformation = {
    id: string | MaxDemoModulePackageId
    externalId?: string
    name: string
    description?: string
    externalUrl?: string
    shortDescriptionImage?: string
    longDescriptionImage?: string
    explanationVideoUrl?: string
}

export enum ModuleOptionType {
    Unknown,
    Demo,
    Full
}

export type ModuleOptionPrice = {
    type: ModuleOptionPriceType
    value: number
    currencySymbol: string
}

export enum ModuleOptionPriceType {
    Unknown = 0,
    PricePerMonth = 1,
    PricePerUse = 2,
}

export type SetModuleActivationRequest = {
    moduleGroupId: string
    moduleId: string
    externalModuleId?: string
    vehicleType: VehicleType
    moduleOption: ModuleOption
    modulePackage?: ModulePackage
}

export type SetModuleActivationResponse = {
}

type Validation = {
    errorCode?: number
    errorMessage?: string
}