import { useMemo } from "react"
import { AvailabilityStatus, ECounterType, ErpInformation, ErpSystemConfig, SuccessArticleErpInfo } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { TmaHelper, useErpConfig } from "@tm/utils"
import { Stack, styled } from "@mui/material"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"

const DEFAULTGAP = 0.5

type AvailabilitiesProps = {
    defaultArticleErpInfo?: SuccessArticleErpInfo
    alternativeArticleErpInfo?: SuccessArticleErpInfo
}

export function Availabilities({ defaultArticleErpInfo, alternativeArticleErpInfo }: AvailabilitiesProps) {
    const { translateText } = useLocalization()
    const AvailabilityComponent = useDefaultArticleItemState((x) => x.AvailabilityComponent)
    const { useOrderByDistributor } = useErpConfig()

    const Availability = useMemo(() => styled(AvailabilityComponent)({ "> div": { display: "block" } }), [AvailabilityComponent])
    const handleClickDetails = useDefaultArticleItemState((x) => x.handleClickDetails)

    function handleAvailabilityClick(erpSystem?: ErpSystemConfig, useSpecialProcurement?: boolean): void {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleDetailsViaAvailability)

        if (useOrderByDistributor && erpSystem?.id && !erpSystem?.isDefaultGetErpInfoSystem) {
            handleClickDetails("partnersystems")
        } else if (useSpecialProcurement) {
            handleClickDetails("special-procurement", "#load-teccom")
        } else {
            handleClickDetails("stocks")
        }
    }

    const erpInfos = useMemo(() => {
        const list: {
            availability: ErpInformation["availability"]
            requestedQuantity?: number
            warehouses?: ErpInformation["warehouses"]
            tour?: ErpInformation["tour"]
            erpSystem?: ErpSystemConfig
        }[] = []
        if (defaultArticleErpInfo) {
            list.push({ ...defaultArticleErpInfo.response, erpSystem: defaultArticleErpInfo.erpSystem })
        } else {
            list.push({ availability: { type: AvailabilityStatus.Unknown, shortDescription: translateText(12684) } })
        }
        if (alternativeArticleErpInfo) {
            list.push({ ...alternativeArticleErpInfo.response, erpSystem: alternativeArticleErpInfo.erpSystem })
        }
        return list
    }, [defaultArticleErpInfo, alternativeArticleErpInfo, translateText])

    return (
        <Stack gap={DEFAULTGAP} flexWrap="wrap" flexDirection="row">
            {erpInfos.map((info, index) => (
                <Availability
                    onClick={(useSpecialProcurement) => handleAvailabilityClick(info.erpSystem, useSpecialProcurement)}
                    key={`availability_${index}`}
                    {...info}
                    erpSystem={useOrderByDistributor ? info.erpSystem : undefined}
                />
            ))}
        </Stack>
    )
}
